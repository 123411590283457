<template>
  <div>
    <h1 class="blue-grey--text nunito lighten-3"><b>Loyalty</b></h1>
    <v-divider></v-divider>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <div class="flex flex-column">
            <h3 class="blue-grey--text nunito lighten-3 mb-2 mt-2">
              <b>Rounding</b>
            </h3>
            <h5 class="nunito mb-2">
              Selected :
              {{
                merchant_detail.rounding == ""
                  ? "no rounding"
                  : merchant_detail.rounding
              }}
            </h5>
            <v-btn-toggle
              class="transparent"
              :value="merchant_detail.rounding"
              @change="updateInput($event, 'rounding')"
            >
              <v-btn value="">
                <span class="hidden-sm-and-down">No Rounding</span>
              </v-btn>

              <v-btn value="round_down">
                <span class="hidden-sm-and-down">Round down</span>
              </v-btn>

              <v-btn value="round_up">
                <span class="hidden-sm-and-down">Round up</span>
              </v-btn>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Multiplier"
            dense
            color="info"
            outlined
            :value="merchant_detail.multiplier"
            @input="updateInput($event, 'multiplier')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <h1 class="blue-grey--text nunito lighten-3"><b>Website</b></h1>
    <v-divider></v-divider>
    <v-container grid-list-lg>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            label="Google Analytic ID"
            dense
            outlined
            color="info"
            :value="merchant_detail.google_analytic_id"
            @input="updateInput($event, 'google_analytic_id')"
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            label="Facebook Pixel ID"
            dense
            color="info"
            outlined
            :value="merchant_detail.facebook_pixel_id"
            @input="updateInput($event, 'facebook_pixel_id')"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
    <div class="flex justify-end mt-5">
      <v-btn color="success" @click="save">save</v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Miscellaneous",
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      merchant_detail: "getImpersonate",
    }),
  },

  methods: {
    updateInput(e, index) {
      this.merchant_detail[index] = e;
      this.$store.commit("updateMerchantSettings", this.merchant_detail);
    },

    save() {
      this.$store.dispatch("startLoad");
      this.$store.dispatch("saveMerchant");
    },
  },
};
</script>
